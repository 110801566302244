import React, {useState, useEffect} from 'react';
import * as Icon from 'react-bootstrap-icons';
import Image from 'react-bootstrap/Image';

import html from  '../common/assets/img/technology/html.png'
import css from  '../common/assets/img/technology/css.png'
import bootstrap from  '../common/assets/img/technology/bootstrap.png'
import javscript from  '../common/assets/img/technology/javascript.png';
import jquery from  '../common/assets/img/technology/jquery.png';
import rectjs from  '../common/assets/img/technology/reactjs.png';
import nodejs from  '../common/assets/img/technology/nodejs.png';
import php from  '../common/assets/img/technology/php.png';
import codeigniter from  '../common/assets/img/technology/codeigniter.png';
import mysql from  '../common/assets/img/technology/mysql.png';
import mongodb from  '../common/assets/img/technology/mongodb.png';
import github from  '../common/assets/img/technology/github.png';
import banner from  '../common/img/banner-3.jpg';
import { Link } from 'react-router-dom';
import { fetchProjects } from './admin/api';
import { Helmet } from "react-helmet";
import { Row,Col } from 'react-bootstrap';

function Home() {
    const [projects,setProjects] = useState([]);
     //state sideeffects
     useEffect(()=>{
        //Get All Projects
        const fetchProjectDatas = async()=>{
            const response = await fetchProjects();
            setProjects(response)
        }
        fetchProjectDatas();        
    },[])
  return (
    <>      
        <div className=''>  
            <Helmet>
                <title>Applot - Software Development</title>
                <meta name="title" content="Applot | Web & Mobile App Development" />
                <meta name="description" content="Welcome to the Applot, a full stack developer specializing in creating responsive websites and mobile applications. Discover my projects, skills, and how I can help bring your ideas to life." />
            </Helmet>
                <section id="hero" className="d-flex flex-column align-items-center justify-content-center">
                    <Image src={banner} fluid alt="" /> 
                    <div className='content'>
                        <h1>Hi, I'm applot!</h1>
                        <h2>Software Development</h2>
                        <a href="#portfolio" className="btn-get-started scrollto">                        
                            <Icon.ChevronDoubleDown />
                        </a>
                    </div>
                </section>  
                
                <section id="services" className="services section-bg">
                    <div className="container"> 
                        <div className="section-title">
                            <h2>Services</h2>
                            <p>Bringing your ideas to life with comprehensive web and application development, from concept to deployment. I specialize in building scalable and robust applications using modern technologies.</p>
                            <p>Providing ongoing support and maintenance to ensure your applications run smoothly. I offer services including performance monitoring, bug fixes, and feature enhancements.</p>
                        </div> 
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box">
                                    <div className="icon"><i><Icon.BrowserChrome /></i></div>
                                    <h4 className="title"><Link >Website</Link></h4>
                                    <p className="description">Crafting responsive and visually stunning websites tailored to your business needs. I ensure your site is user-friendly, optimized for performance, and scalable for future growth.</p>
                                </div>
                            </div> 
                            <div className="col-md-6   d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box">
                                    <div className="icon"><i><Icon.Android /></i></div>
                                    <h4 className="title"><Link >Mobile App</Link></h4>
                                    <p className="description">Building intuitive and engaging mobile applications for iOS and Android platforms. I focus on creating seamless user experiences with robust functionality and high performance</p>
                                </div>
                            </div>
                        </div> 
                    </div>
                </section>

                <section id="portfolio" className="portfolio section-bg">
            <div className="container">

                <div className="section-title">
                <h2>Projects</h2>
                <p>List of completed Projects...</p>
                </div>

                {/* <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                    <ul id="portfolio-flters">
                    <li data-filter="*" className="filter-active">All</li>
                    <li data-filter=".filter-app">App</li>
                    <li data-filter=".filter-card">Card</li>
                    <li data-filter=".filter-web">Web</li>
                    </ul>
                </div>
                </div> */}

                <div className="row portfolio-container">

                {projects.length >0 && projects.map((project_data, index) =>(

                    <div className="col-lg-4 col-md-6 portfolio-item filter-app" key={index}>
                    <Link to={`/project-details/${project_data.project_id}`} title="More Details">
                        <div className="portfolio-wrap"> 
                            <Image src={project_data.image} className="img-fluid" alt="" /> 
                            <div className="portfolio-info">
                                <h4>{project_data.project_name}</h4>
                                <p>{project_data && project_data.category}</p>
                            </div>
                            <div className="portfolio-links">
                                <a href={project_data.image} data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a>
                            </div> 
                        </div>
                    </Link>
                    </div>

                    ))

                }

                   
                </div> 
            </div>
            </section>
            <section className='technology'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 section-title'>
                            <h2>Technology</h2>
                            <Row className='mb-5'>
                                <Col xs={2} sm={2}>
                                    <Image src={html} alt="html" fluid  /> 
                                </Col>
                                <Col xs={2} sm={2}>
                                    <Image src={css} alt="css" fluid /> 
                                </Col>
                                <Col xs={2} sm={2}>
                                    <Image src={bootstrap} alt="bootstrap" fluid /> 
                                </Col>
                                <Col xs={2} sm={2}>                           
                                    <Image src={javscript} alt="javascript" fluid />
                                </Col>
                                <Col xs={2} sm={2}>
                                    <Image src={jquery} alt="jquery" fluid /> 
                                </Col>
                                <Col xs={2} sm={2}>
                                    <Image src={rectjs} alt="rectjs" fluid /> 
                                </Col>
                            </Row>
                            <Row> 
                                <Col xs={2} sm={2}>
                                    <Image src={nodejs} alt="nodejs" fluid /> 
                                </Col>
                                <Col xs={2} sm={2}>                            
                                    <Image src={php} alt="php" fluid />
                                </Col>
                                <Col xs={2} sm={2}>
                                    <Image src={codeigniter} alt="codeigniter" fluid /> 
                                </Col>
                                <Col xs={2} sm={2}> 
                                    <Image src={mysql} alt="mysql" fluid /> 
                                </Col>
                                <Col xs={2} sm={2}> 
                                    <Image src={mongodb} alt="mongodb" fluid /> 
                                </Col>
                                <Col xs={2} sm={2}>                           
                                    <Image src={github} alt="github" fluid />
                                </Col>
                            </Row> 
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
  );
}


export default Home;
