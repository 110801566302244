import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './common/style.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import Contact from './components/Contact';

import ProjectDetails from './components/admin/ProjectDetails';
import Login from './components/admin/Login';
import Banner from './components/admin/Banner';
import Projects from './components/admin/Projects';
import Register from './components/admin/Register';
import ProtectedRoute from './components/admin/ProtectedRoute';
import { Provider } from 'react-redux';
import store from './redux/app/store';
import ScrollTotop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
       <Provider store={store}> 
        <Router>
            <ScrollTotop />
            <Routes>
                <Route path="/" element={<Header />}>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/contact" element={<Contact />}></Route>
                    <Route path="/project-details/:id" element={<ProjectDetails />}></Route>
                    <Route path="/admin/login" element={<Login />}></Route>
                    <Route path="/admin/register" element={<Register />}></Route>
                    <Route path='/' element={<ProtectedRoute />}>
                        <Route path="/admin/banner" element={<Banner />}></Route>
                        <Route path="/admin/projects" element={<Projects />}></Route>
                        <Route path="/admin/project-details/:id" element={<ProjectDetails />}></Route>
                    </Route> 
                </Route>
            </Routes>
        </Router>   
        <Footer />   
      </Provider>
    </div>
  );
}

export default App;
