import React,{useState}  from "react";
import { Container,Row,Col, Card, Form, Button } from "react-bootstrap";
import { useSelector,  useDispatch } from "react-redux";
import { clearError, clearMessage, loginUser } from "../../redux/features/loginSlice";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Login(){

    const navigate = useNavigate();
    const {message, error} = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [frmData, setFrmData] = useState({
        email: "",
        password:""
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFrmData({
            ...frmData,
            [name]:value
        })
     }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('data',JSON.stringify(frmData))        
        dispatch(loginUser(postData));
    }

    if(message){
        alert(message);
       // toast.success(message);
        dispatch(clearMessage());
       navigate('/admin/projects',{replace:true})

    }
    if(error){
        alert(error);
        //toast.error(error);
        dispatch(clearError());

    }
    return(
        <Container>
            <Helmet >
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Row className=" mb-5">
                <Col></Col>
                <Col className="mt-5">
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-center">Login</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" value={frmData.email} onChange={handleChange} name="email" placeholder="Enter Email" required></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password"  value={frmData.password} onChange={handleChange} name="password" placeholder="Enter password" required></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3 float-end">
                                     <Button type="submit" variant="primary">Login</Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}

export default Login;