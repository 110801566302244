import React, {useState, useEffect} from 'react';
import { Modal, Form, Button, Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { fetchProjects } from './api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function ProjectModel({ProjectDetails, setProjects, show, handleClose}) {

    const API_URL = process.env.REACT_APP_API_URL;

    //state
    const initialImgData = [{image: ''}];
    const [moreImage,setMoreImage] =useState(initialImgData); 
    const initialFrmData = {
        project_id:'',
        project_name:'',
        project_details:'',
        title:'',
        meta_title:'',
        meta_description:'',
        front_end:'',
        back_end:'',
        db_name:'',
        category:'',
        url:'',
        folder: Date.now(),
        project_image_more:[]
    }
    const [frmData, setFrmData] = useState(initialFrmData);  

    //state side effect
    useEffect(()=> {
        if(ProjectDetails){            
            setFrmData(                
                {
                    project_id:ProjectDetails.project_id,
                    project_name:ProjectDetails.project_name,
                    project_details:ProjectDetails.project_details,
                    title:ProjectDetails.title,
                    meta_title:ProjectDetails.meta_title,
                    meta_description:ProjectDetails.meta_description,
                    front_end:ProjectDetails.front_end,
                    back_end:ProjectDetails.back_end,
                    db_name:ProjectDetails.db_name,
                    category:ProjectDetails.category,
                    url:ProjectDetails.url,
                    project_image_more:[]
                }
            )
        }
    },[ProjectDetails])

    const handleAddRow = () => {
        const newRow = [
                ...moreImage,
                {
                    image:''
                }
        ]
        setMoreImage(newRow);
        //console.log(newRow);
    }

    const handleRemoveRow = (i) => {
        const images = [...moreImage];
        if(images.length >1){
            images.splice(i,1);       
            setMoreImage(images);
        }else {
            alert('couldn"t remove first row')
        }
        
    }

    const handleChange = (e) => {       
        const {name,value, files} = e.target;
        console.log(frmData)
        if(files){            
            setFrmData({
                ...frmData,
                project_image_more:[
                    ...frmData.project_image_more,
                    files[0]
                ]
            })
        }else {
            setFrmData({
            ...frmData,
                [name]:value
            })
        }        
    }

    const handleProjectChange = (value) => {           
        frmData.project_details = value;
    }

    const handleSubmit = async (e) => {
        
        e.preventDefault(); 
        console.log(frmData);
        const postData = new FormData();
        console.log(frmData);
        if(frmData.project_image_more.length >0){
            for(let i=0; i<frmData.project_image_more.length; i++){
                postData.append(`project_image_more[]`, frmData.project_image_more[i]);
            }
        }
		postData.append(`data`,JSON.stringify(frmData));
        let url =API_URL;
        if(frmData.project_id){
            url +="/project/edit-project";
        }else {
            url +="/project/add-project";
        }
        try{
            const response = await axios.post(url,postData);
            if(response.status){
                alert('success');
                setFrmData(initialFrmData);
                setMoreImage(initialImgData)
                const fetchProjectDatas = async()=>{
                    const response = await fetchProjects();
                    setProjects(response)
                }        
                fetchProjectDatas();
                handleClose();  

            } 
        }catch(error){
            console.log(error);
        } 
        
    }
    return ( 
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Banner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType='multipart/form-data'> 
                    <Form.Group className="mb-3">
                        <Form.Label>Project Name *</Form.Label>
                        <Form.Control type="text" value={frmData.project_name} onChange={handleChange} name="project_name" placeholder="Enter Project Name"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-5">
                        <Form.Label>Project Details *</Form.Label>
                        <ReactQuill
                            className="react-quill-custom"
                            value={frmData.project_details} 
                            onChange={ handleProjectChange}
                            modules={ProjectModel.modules}
                            formats={ProjectModel.formats}
                            placeholder="Enter Project details"
                            required
                        /> 
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Title *</Form.Label>
                        <Form.Control type="text" value={frmData.title} onChange={handleChange} name="title" placeholder="Enter Title"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Meta Title *</Form.Label>
                        <Form.Control type="text" value={frmData.meta_title} onChange={handleChange} name="meta_title" placeholder="Enter Meta Title"></Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Meta Description *</Form.Label>
                        <Form.Control as="textarea" value={frmData.meta_description} onChange={handleChange} name="meta_description" placeholder="Enter Meta Description"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-5">
                        <Form.Label>Front End *</Form.Label>
                        <Form.Control type="text" value={frmData.front_end} onChange={handleChange} name="front_end" placeholder="Enter Front End"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Back End *</Form.Label>
                        <Form.Control type="text"  value={frmData.back_end} onChange={handleChange} name="back_end" placeholder="Enter Back End"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Database *</Form.Label>
                        <Form.Control type="text"  value={frmData.db_name} onChange={handleChange} name="db_name" placeholder="Enter Databse"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Category *</Form.Label>
                        <Form.Select name="category"  value={frmData.category} onChange={handleChange} aria-label="Default select example">
                            <option value="">---Select---</option>
                            <option value="Website">Website</option>
                            <option value="Mobile App">Mobile App</option> 
                            <option value="Web And Mobile App">Web And Mobile App</option> 
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>URL *</Form.Label>
                        <Form.Control type="text" name="url"  value={frmData.url} onChange={handleChange} placeholder="Enter URL"></Form.Control>
                    </Form.Group> 
                    <Row>
                        <Col className={12}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Project Image</th>
                                        <th><Button variant='success' size="sm" onClick={handleAddRow}  className='btn btn-sm' >Add</Button></th>
                                    </tr>
                                </thead>
                                <tbody> 
                                        {moreImage && moreImage.map((more_image_data, i) =>(
                                            <tr>
                                                <td>{i+1}</td>
                                                <td>
                                                    <Form.Group className="mb-3"> 
                                                        <Form.Control name="project_image_more[]"  onChange={handleChange} type="file"   ></Form.Control>
                                                    </Form.Group> 
                                                </td>
                                                <td>
                                                    <Form.Group className='mb-3'>
                                                        <Button variant='danger' onClick={() => handleRemoveRow(i)}   className='btn btn-sm'>Remove</Button>
                                                    </Form.Group>
                                                </td>
                                            </tr>                                                
                                            ))
                                        }  
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                   
                    <Form.Group>
                        <Button type="submit" className="float-end" variant="primary">Update</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal> 
    )
}

// Specify the modules to include in the toolbar
ProjectModel.modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };
  
  // Specify the formats
  ProjectModel.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];  


export  default ProjectModel;