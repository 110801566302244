import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

function BannerModal({show, handleClose}) {
    return(
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Banner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="file"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Title *</Form.Label>
                        <Form.Control type="text" name="title" placeholder="Enter Title"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Details *</Form.Label>
                        <Form.Control as="textarea" name="title" placeholder="Enter Details"></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Button type="submit" className="float-end" variant="primary">Update</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export  default BannerModal;