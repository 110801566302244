import React, { useState } from "react";
import { Container,Row,Col, Card, Button, Table, Image } from "react-bootstrap";
import portfolio1 from '../../common/assets/img/portfolio/portfolio-1.jpg';
import BannerModal from "./BannerModal"; 

function Banner() {
    const [show,setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <Container>
            <Row>
                <Col></Col>
                <Col>
                    <Card className="mt-5 mb-5"> 
                        <Card.Header className="">
                            <Button variant="success" onClick={handleShow} className="float-end">Edit</Button>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>Banner</Card.Title>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th>Image</th>
                                        <td>
                                            <Image src={portfolio1} style={{"height":"128px", "width":"128px"}} thumbnail />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Title</th>
                                        <td>:wfewrewer</td>
                                    </tr>
                                    <tr>
                                        <th>Details</th>
                                        <td>:erererewr</td>
                                    </tr> 
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card> 
                </Col>
                <Col></Col>
            </Row>
            {/**** Banner Modal */}
            <BannerModal show={show} handleClose={handleClose} />
        </Container>
    );
}
export default Banner