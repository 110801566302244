import React,{useState} from "react";
import Form from 'react-bootstrap/Form';
import {Link } from "react-router-dom";
import logo from  '../common/img/logo-no-background.png';
import Image from 'react-bootstrap/Image';
import * as Icon from 'react-bootstrap-icons';
import axios from "axios";

function Contact(){
    const API_URL = process.env.REACT_APP_API_URL;
    const initialFrmData = {
            name: '',
            email:'',
            mobile_no:'',
            country:'',
            state:'',
            city:'',
            message:''
        }
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [frmData,setFrmData] = useState(initialFrmData)

    const handleChange = (e) => {
        const {name,value} = e.target;
        setFrmData({
            ...frmData,
            [name]:value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('')
        const postData = new FormData();
        postData.append('data', JSON.stringify(frmData));

        setIsLoading(true);
        try{
            const response =  await axios.post(API_URL+"/contact", postData);

            if(response.data.status === true){
                setMessage(response.data.message);
                setFrmData(initialFrmData);
            }else {
                setError('Something went wrong. Please try again later!')
            }

        }catch(error){
            console.log(error);
            setError('Something went wrong. Please try again later!')

        } finally{
            setIsLoading(false);
        }
    }
    return(
        <>
            <section id="contact" className="contact container section-bg">
                <div className="">
                    <div className="section-title">
                        <h2>Contact</h2>
                        <p>I'm always excited to hear about new opportunities and collaborations. Whether you have a project in mind, need technical advice, or just want to connect, feel free to reach out!</p>
                    </div> 
                    <div className="row"> 
                        <div className="col-sm-6 mb-5">
                            <div className="contact-about text-center">
                                {/* <h3>AppLot</h3> */}
                                <Image src={logo} alt="logo" />
                                {/* <p></p>
                                <div className="social-links">
                                    <Link className="twitter"><i className="bi bi-twitter"></i></Link>
                                    <Link className="facebook"><i className="bi bi-facebook"></i></Link>
                                    <Link className="instagram"><i className="bi bi-instagram"></i></Link>
                                    <Link className="linkedin"><i className="bi bi-linkedin"></i></Link>
                                </div> */}
                                <p></p>
                                <div className="info">
                                    <div className=" align-items-center"> 
                                        <Icon.GeoAlt className="address" />
                                        <p><Link>Anthiyur, Erode - 638501</Link> </p>
                                    </div>
                                    <div className="align-items-center mt-4">  
                                        <Icon.Envelope className="mail" />
                                        <p><Link to={`mailto:contact@applot.in`}>contact@applot.in</Link></p>
                                    </div> 
                                     
                                    <div className=" align-items-center mt-4">
                                        <Icon.Whatsapp className="whatsapp" />
                                        <p> <Link to={`https://wa.me/+919095960991?text=Hello Applot,%20I%20would%20like%20to%20discuss...`}  target="_blank">+91 9095960991</Link></p>   
                                    </div> 
                                </div>
                                 
                            </div>
                        </div>

                        {/* <div className="col-lg-3 col-md-4">
                            <div className="info">
                                <div className="d-flex align-items-center"> 
                                    <Icon.GeoAlt />
                                    <p> Anthiyur, Erode - 638312</p>
                                </div>
                                <div className="d-flex align-items-center mt-4">  
                                    <Icon.Envelope />
                                    <p>enquiry@applot.com</p>
                                </div> 
                                <div className="d-flex align-items-center mt-4">
                                    <Icon.Phone />
                                    <p>+91 9095960991</p>
                                </div> 
                            </div>
                        </div>  */}
                        <div className="col-sm-6">
                            <Form  onSubmit={handleSubmit} className="php-email-form">
                                <div className="form-group">
                                    <input type="text" value={frmData.name} onChange={handleChange} name="name" className="form-control" id="name" placeholder="Your Name *" required />
                                </div>
                                <div className="form-group mt-3">
                                    <input type="email" value={frmData.email} onChange={handleChange} className="form-control" name="email" id="email" placeholder="Your Email *" required />
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" value={frmData.mobile_no} onChange={handleChange}  className="form-control" name="mobile_no" placeholder="Mobile Number *" required />
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" value={frmData.country} onChange={handleChange}  className="form-control" name="country" placeholder="Country *" required />
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" value={frmData.state} onChange={handleChange}  className="form-control" name="state" placeholder="State *" required />
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" value={frmData.city} onChange={handleChange}  className="form-control" name="city" placeholder="city" />
                                </div>
                                <div className="form-group mt-3">
                                    <textarea value={frmData.message} onChange={handleChange}  className="form-control" name="message" rows="5" placeholder="Message *" required></textarea>
                                </div>
                                <div className="my-3">
                                    <div className={`loading ${(isLoading) ? 'show' : ''}`}>Loading</div>
                                    <div className={`error-message ${(error) ? 'show' : ''}`}>{error}</div>
                                    <div className={`sent-message  ${(message) ? 'show' : ''}`}>{message}</div>
                                </div>
                                <div className="text-center"><button type="submit">Send Message</button></div>
                            </Form>
                        </div> 
                    </div> 
                </div>
            </section>
        </>
    )
}

export default Contact;