import React,{useState,useEffect} from 'react';
import * as Icon from 'react-bootstrap-icons';
import logo from  '../common/img/logo-no-background.png';
import Image from 'react-bootstrap/Image';
 
import { Outlet, Link, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../redux/features/loginSlice';

function Header() {
    const [showIcon, setShowIcon] = useState(false);
    const dispatch = useDispatch();
    const {isAuth, authUser} = useSelector((state)=> state.user);

    const {pathname} = useLocation();

    useEffect(()=>{
        setShowIcon(false);
    },[pathname]);
    const handleMenu = () => {
        
        setShowIcon((showIcon) ? false : true);
    }
    const handleLogout = () => {
        dispatch(logout());
    }
  return (
    <>
    <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
            <div className="logo">
                {/* <h1><Link to="/">Applot</Link></h1>  */}

                <Link to="/"> <Image src={logo} alt="" /></Link>
            </div>
            <nav id="navbar" className={`navbar  ${(showIcon) ? 'navbar-mobile':''}`}>
                <ul>
                    <li>
                        <Link className='nav-link scrollto' to="/" >Home</Link></li>
                   
                    <li> <Link className='nav-link scrollto' to="/contact"> Contact </Link></li>
                    {/* <li> <Link className='nav-link scrollto' to="/admin/register"> Register </Link></li>
                    <li> <Link className='nav-link scrollto' to="/admin/login"> Login </Link></li>
                    <li> <Link className='nav-link scrollto' to="/admin/banner"> Banner </Link></li>
                    <li> <Link className='nav-link scrollto' to="/admin/projects"> Projects </Link></li> */}

                    {isAuth && <li> <Link  style={{"color":"red"}} onClick={handleLogout}> {`${authUser.email} (logout)`} </Link></li>}
                </ul>
                {/* <i className="bi bi-list mobile-nav-toggle"></i> */}
                <Icon.List onClick={handleMenu}  className={`mobile-nav-toggle list ${(!showIcon) ? 'show':''}`} />
               <Icon.X  onClick={handleMenu}  className={`mobile-nav-toggle close  ${(showIcon) ? 'show':''}`}  />
            </nav> 
        </div>
    </header>
    <Outlet />
    </>
  );
}

export default Header;