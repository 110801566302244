import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authUser } from "../../redux/features/loginSlice";
function ProtectedRoute(){
    const {token, isAuth} = useSelector((state)=> state.user);
    const dispatch = useDispatch();

    useEffect(()=> {
        if(isAuth){
            dispatch(authUser(token));
        }

    },[isAuth,dispatch,token])
    return(
        (isAuth) ? <Outlet /> : <Navigate to="/" />
    )
}

export default ProtectedRoute;