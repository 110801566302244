import React,{useState, useEffect} from "react";
import  Image  from "react-bootstrap/Image";
import { Link, useNavigate, useParams } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import { fetchProjectDetails} from './api';
import { Helmet } from "react-helmet";
import Contact from "../Contact";
import { Modal } from "react-bootstrap";


function ProjectDetails() {
    const [ProjectDetails, setProjectDetails] = useState("");

    const {id} = useParams();
    const navigate =useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [currentImage, setCurrentImage] = useState('');

    const handleImageClick = (image) => {

        setCurrentImage(image);
        setShowModal(true);
    }

    useEffect(()=> { 
        if(id){
            const fetchProjectData = async()=>{
                const response = await fetchProjectDetails(id);
                 
                if(response === false){
                    navigate('/',{replace:true})
                }else {
                     // console.log()
                    setProjectDetails(response)
                }
              
            }        
            fetchProjectData();
        }

    },[id,navigate])
    return (
        <>
        <Helmet>
                <title> {(ProjectDetails) ? ProjectDetails.title: 'Applot - Software Development'}</title>
                <meta name="title" content={(ProjectDetails) ? ProjectDetails.meta_title: 'Applot | Web & Mobile App Development'} />
                
                <meta name="description" content={(ProjectDetails) ? ProjectDetails.meta_description : 'Welcome to the Applot, a full stack developer specializing in creating responsive websites and mobile applications. Discover my projects, skills, and how I can help bring your ideas to life.'} />
            </Helmet>
            <div id="portfolio-details" className="portfolio-details">
                <div className="container"> 
                    <div className="row gy-4"> 
                        <div className="col-lg-8">
                            <Carousel  data-bs-theme="dark">
                                {ProjectDetails && ProjectDetails.other_images &&
                                    ProjectDetails.other_images.map((image_data, index)=> (
                                        <Carousel.Item key={index+1}>
                                            <Image src={image_data.image} onClick={() => handleImageClick(image_data.image)} className="img-fluid" alt="" /> 
                                         </Carousel.Item>
                                    ))
                                
                                }
                              
                                {/* <Carousel.Item>
                                    <Image src={portfolio2} className="img-fluid" alt="" /> 
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Image src={portfolio3} className="img-fluid" alt="" /> 
                                </Carousel.Item>  */}
                            </Carousel> 
                        </div> 
                        <div className="col-lg-4">
                            <div className="portfolio-info">
                                <h3>Project information</h3>
                                <ul>
                                <li><strong>Project Name</strong>: {ProjectDetails && ProjectDetails.project_name}</li>
                                    <li><strong>Category</strong>: {ProjectDetails && ProjectDetails.category}</li>
                                    <li><strong>Front End</strong>: {ProjectDetails && ProjectDetails.front_end}</li>
                                    <li><strong>Back End</strong>: {ProjectDetails && ProjectDetails.back_end}</li>
                                    <li><strong>Database</strong>: {ProjectDetails && ProjectDetails.db_name}</li>
                                    <li><strong>URL</strong>: <Link to={ProjectDetails && ProjectDetails.url} target="_blank" >{ProjectDetails && ProjectDetails.url}</Link></li>
                                </ul>
                            </div> 
                        </div> 
                    </div> 
                    {/* Modal for image zoom */}
                    <Modal show={showModal} onHide={()=>setShowModal(false)} size="lg" centered>
                    <Modal.Header closeButton>
                        
                    </Modal.Header>
                        <Modal.Body>
                            <Image src={currentImage} fluid alt="zoomed" />
                        </Modal.Body>
                    </Modal>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="portfolio-description">
                                <h2>Project Details : </h2> 
                                <div dangerouslySetInnerHTML={{__html:ProjectDetails && ProjectDetails.project_details}} /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="contact us">
                <Contact />
            </div>
        </>
    )
}

export default ProjectDetails;