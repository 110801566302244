import React, {useState,useEffect} from "react";
import { Button, Col, Container, Image, Row, Table } from "react-bootstrap";
import ProjectModel from "./ProjectModel";
import { Link } from "react-router-dom";
import { fetchProjects } from './api';
import axios from "axios";
import { Helmet } from "react-helmet";

function Projects(){

    const API_URL = process.env.REACT_APP_API_URL;

    //States
    const [show,setShow] = useState(false);
    const [projects,setProjects] = useState([]);
    const [ProjectDetails,setProjectDetails] = useState(''); 

    //state sideeffects
    useEffect(()=>{
        //Get All Projects
        const fetchProjectDatas = async()=>{
            const response = await fetchProjects();
            setProjects(response)
        }
        fetchProjectDatas();        
    },[])

    //Project Modal Action
    const handleShow = (id) => {  
        if(id){
            const project_details = projects.find((project_data) => project_data.project_id === id);
            console.log(project_details);
            setProjectDetails(project_details);      
        }    
        setShow(true);          
    }

    const handleClose = () => setShow(false);

    //Delete Project
    const handleDelete = async (id) => {
        if(window.confirm('Are you sure you want delete!')){
            try{
                const postData =  new FormData();
                postData.append('project_id', id);
                const response =  await axios.post(API_URL+"/project/delete-project",postData);
                setProjects(response.data.projects);
            }catch(error){
                console.log(error);
            }
        }        
    }
   
    return(
        <Container>
            <Helmet> 
                <meta name="robots" content="noindex, nofollow" /> 
            </Helmet>
            <Row>
                <Col className="mt-5 mb-5">
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th colSpan={10}><Button onClick={() =>handleShow("")} className="float-end" variant="primary" size="sm">Add</Button></th>
                            </tr>
                            <tr>
                                <th>#</th>
                                <th>Main Image</th>
                                <th>Project Name</th> 
                                <th>Front End</th> 
                                <th>Back End</th>  
                                <th>Database</th>
                                <th>Category</th>
                                <th>Url</th>
                                <th>Action</th>   
                                                         
                            </tr>
                        </thead>
                        <tbody>
                            {projects && projects.map((project_data, index) =>(
                                <tr key={project_data.project_id}>
                                    <td>{index+1}</td>
                                    <td><Image src={project_data.image} style={{"height":"64px", "width":"64px"}} /> </td>
                                    <td>{project_data.project_name}</td>
                                    
                                    <td>{project_data.front_end}</td>
                                    <td>{project_data.back_end}</td>
                                    <td>{project_data.db_name}</td>
                                    <td>{project_data.category}</td>
                                    <td>{project_data.url}</td>
                                    <td style={{"width":"15%"}}>
                                        <Link to={`/admin/project-details/${project_data.project_id}`}   className="btn btn-sm btn-primary" size="sm">View</Link> | 
                                        <Button  onClick={()=>handleShow(project_data.project_id)} variant="warning" size="sm">Edit</Button> | 
                                        <Button onClick={() => handleDelete(project_data.project_id)} variant="danger" size="sm">Delete</Button>
                                    </td>                                 
                                </tr>

                            ))}

                            {/* Result not found */}

                            {  
                                !projects &&
                                <tr>
                                    <td colSpan={9} className="text-center">No Records Found</td>
                                </tr>
                            }
                            
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <ProjectModel ProjectDetails={ProjectDetails} setProjects={setProjects}  show={show} handleClose={handleClose} />
        </Container>
    )
}
export default Projects;