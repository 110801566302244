import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

function Register(){
    return (
        <Container>
            <Row className="mt-5">
                <Col sm={4}></Col>
                <Col sm={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-center">Register</Card.Title>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="Enter Name" required></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Enter Email" required></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" placeholder="Enter Password" required></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Button variant="primary" className="float-end" type="submit">Register</Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4}></Col>
            </Row>
        </Container>
    )
}
export default Register;