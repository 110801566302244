import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

//Get all projects
export const fetchProjects = async()=> {
    try{
        
        const response = await axios.get(`${API_URL}/projects`);
        return response.data.projects
    }catch(error){
        return error.response.data
    }

}

//Get single project
export const fetchProjectDetails = async(id)=> {
    try{
        
        const response = await axios.get(`${API_URL}/projects/${id}`);
        
        if(response.data.status === true){
            return response.data.projects[0];
        }else {
            return false;
        }
      
    }catch(error){
        return error.response.data
    }

}